<template>
  <div class="container mx-auto p-4">
    <div class="flex justify-between items-center mb-6">
      <h1 class="text-3xl font-bold text-center text-blue-600">{{ t.title }}</h1>
      <div class="flex items-center space-x-4">
        <button @click="toggleLanguage" class="text-sm font-medium hover:underline underline-offset-4">
          {{ currentLanguage === 'en' ? '中文' : 'English' }}
        </button>
        <SignedIn forceRedirectUrl="/upload">
              <UserButton showName="true"/>
        </SignedIn>
      </div>
    </div>
    
    <div v-if="isSignedIn">
      <!-- 原有的结果内容 -->
      <div v-if="optimizedResume" class="space-y-6">
        <!-- 合并的卡片：简短总结、得分比较和修改说明 -->
        <div class="bg-white shadow-lg rounded-lg p-6 border-t">
          <!-- 简短总结 -->
          <div class="mb-6 bg-blue-50 border-l-4 border-blue-500 p-4">
            <h2 class="text-xl font-semibold mb-2 text-blue-700">{{ t.optimizationSummary }}</h2>
            <p class="text-gray-800 italic">{{ summary }}</p>
          </div>

          <!-- 得分比较 -->
          <div class="mb-6 flex justify-center items-center space-x-4">
            <div class="text-center">
              <div class="text-3xl font-bold text-gray-700">{{ originalScore }}</div>
              <div class="text-sm text-gray-500">{{ t.originalScore }}</div>
            </div>
            <div class="text-4xl text-blue-500">→</div>
            <div class="text-center">
              <div class="text-3xl font-bold text-blue-600">{{ optimizedScore }}</div>
              <div class="text-sm text-gray-500">{{ t.optimizedScore }}</div>
            </div>
          </div>

          <!-- 修改说明 -->
          <div>
            <div v-html="renderedModificationReasons" class="markdown-body prose prose-sm max-w-none"></div>
          </div>
        </div>

        <!-- 优化后的简历内容卡片 -->
        <h2 class="text-xl font-semibold mb-2 text-blue-700">{{ t.optimizedResumeContent }}</h2>
        <div id="pdf-content" class="bg-white shadow-lg rounded-lg p-6 border-t relative">
          <div v-html="renderedOptimizedResume" class="markdown-body prose prose-sm max-w-none mb-16"></div>
          <div class="absolute bottom-4 right-4 flex space-x-2">
            <button @click="copyOptimizedResume" class="px-3 py-1 text-sm bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition duration-300">
              {{ t.copyAsMarkdown }}
            </button>
            <button @click="saveToPDF" class="px-3 py-1 text-sm bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition duration-300">
              {{ t.saveAsPDF }}
            </button>
          </div>
        </div>
        <!-- 面试题和答案卡片 -->
        <h2 class="text-xl font-semibold mb-4 text-blue-700">{{ t.interviewPreparationQuestions }}</h2>
        <div class="bg-white shadow-lg rounded-lg p-6 border-t relative">
          <div id="qa-content" v-html="renderedQA" class="markdown-body prose prose-sm max-w-none mb-16"></div>
          <div class="absolute bottom-4 right-4 flex space-x-2">
            <button @click="copyQA" class="px-3 py-1 text-sm bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition duration-300">
              {{ t.copyAsMarkdown }}
            </button>
            <button @click="saveQAToPDF" class="px-3 py-1 text-sm bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition duration-300">
              {{ t.saveAsPDF }}
            </button>
          </div>
        </div>
      </div>
      <div v-else class="text-red-500 text-center">
        {{ t.networkError }}
      </div>
    </div>

    <!-- 页脚部分 -->
    <footer class="mt-8 py-4 border-gray-200">
      <div class="text-center text-sm text-gray-600" v-html="t.footer"></div>
    </footer>
  </div>
</template>

<script setup>
import { useUser, UserButton } from 'vue-clerk'
import { marked } from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import html2pdf from 'html2pdf.js';
import { inject, computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const currentLanguage = inject('currentLanguage', 'en');
const toggleLanguage = inject('toggleLanguage', () => {});
const { isSignedIn } = useUser();
const router = useRouter();

const optimizedResume = ref('');
const modificationReasons = ref('');
const summary = ref('');
const originalScore = ref(0);
const optimizedScore = ref(0);
const originalFileName = ref('');
const qa = ref('');

const translations = {
  en: {
    title: 'OfferWink - Your Resume Optimization Expert',
    optimizationSummary: 'Optimization Summary',
    originalScore: 'Original Score',
    optimizedScore: 'Optimized Score',
    optimizedResumeContent: 'Optimized resume content',
    copyAsMarkdown: 'Copy as Markdown',
    saveAsPDF: 'Save as PDF',
    interviewPreparationQuestions: 'Interview Preparation Questions',
    networkError: 'Network is unstable, please try again later.',
    footer: '© 2025 by <a href="https://wink.run" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">wink.run</a>. All rights reserved. Learn more about our <a href="https://wink.run/policy.html" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">Privacy Policy</a>.',
    copySuccess: ' has been copied to clipboard!',
    copyFailed: 'Copy failed. Please try again.',
    pdfSaveSuccess: 'PDF has been saved successfully!',
    pdfSaveFailed: 'Failed to save PDF. Please try again.',
    signIn: 'Sign In',
    loginPrompt: 'Please sign in to view your resume results',
  },
  zh: {
    title: 'OfferWink-您的简历优化专家',
    optimizationSummary: '优化总结',
    originalScore: '原始得分',
    optimizedScore: '优化后得分',
    optimizedResumeContent: '优化后的简历内容',
    copyAsMarkdown: '复制为 Markdown',
    saveAsPDF: '保存为 PDF',
    interviewPreparationQuestions: '面试准备问题',
    networkError: '网络不稳定，请稍后重试。',
    footer: '© 2025 <a href="https://wink.run" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">wink.run</a> 出品。保留所有权利。了解我们的<a href="https://wink.run/policy.html" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">隐私权政策</a>.',
    copySuccess: '已复制到剪贴板！',
    copyFailed: '复制失败，请重试。',
    pdfSaveSuccess: 'PDF已成功保存！',
    pdfSaveFailed: 'PDF保存失败，请重试。',
    signIn: '登录',
    loginPrompt: '请登录以查看您的简历结果',
  },
};

const t = computed(() => translations[currentLanguage.value]);

onMounted(() => {
  if (!isSignedIn.value) {
    router.push('/upload'); // 重定向到简历上传页面
  } else {
    loadResumeData();
  }
});

function loadResumeData() {
  const resumeData = JSON.parse(sessionStorage.getItem('resumeData') || '{}');
  optimizedResume.value = resumeData.optimized_resume || '';
  modificationReasons.value = resumeData.modification_reasons || '';
  summary.value = resumeData.summary || '';
  originalScore.value = resumeData.original_score || 0;
  optimizedScore.value = resumeData.optimized_score || 0;
  originalFileName.value = sessionStorage.getItem('originalFileName') || '';
  qa.value = resumeData.qa || '';
}

const renderedOptimizedResume = computed(() => {
  return renderMarkdown(optimizedResume.value);
});

const renderedModificationReasons = computed(() => {
  return renderMarkdown(modificationReasons.value);
});

const renderedQA = computed(() => {
  return renderMarkdown(qa.value);
});

function renderMarkdown(content) {
  if (content) {
    marked.setOptions({
      highlight: function (code, lang) {
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
      langPrefix: 'hljs language-'
    });
    return marked(content);
  }
  return '';
}

function copyOptimizedResume() {
  navigator.clipboard.writeText(optimizedResume.value).then(() => {
    alert(t.value.optimizedResumeContent + t.value.copySuccess);
  }).catch(err => {
    console.error('复制失败:', err);
    alert(t.value.copyFailed);
  });
}

function saveToPDF() {
  const element = document.getElementById('pdf-content');
  const originalName = originalFileName.value.split('.').slice(0, -1).join('.');
  const pdfFileName = `${originalName}_${t.value.optimized}.pdf`;

  const opt = {
    margin: 10,
    filename: pdfFileName,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  };

  html2pdf().from(element).set(opt).save();
}

function copyQA() {
  navigator.clipboard.writeText(qa.value).then(() => {
    alert(t.value.copySuccess);
  }).catch(err => {
    console.error('复制失败:', err);
    alert(t.value.copyFailed);
  });
}

function saveQAToPDF() {
  const element = document.getElementById('qa-content');
  const pdfFileName = `${t.value.interviewPreparationQuestions}_${removeFileExtension(originalFileName.value)}.pdf`;

  const opt = {
    margin: 10,
    filename: pdfFileName,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  };

  html2pdf().from(element).set(opt).save();
}

function removeFileExtension(filename) {
  return filename.split('.').slice(0, -1).join('.');
}
</script>

<style>
@import 'github-markdown-css/github-markdown.css';

.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .markdown-body {
    padding: 15px;
  }
}

.prose {
  max-width: none;
}

/* 添加新的样式 */
.bg-blue-50 {
  background-color: #eff6ff;
}

.border-blue-500 {
  border-color: #3b82f6;
}

.text-blue-700 {
  color: #1d4ed8;
}

.text-blue-600 {
  color: #2563eb;
}
</style>

<style scoped>
/* 如果需要，在这里添加任何额外的样式 */
footer {
  margin-top: auto;
}
</style>