<template>
  <div class="min-h-screen flex flex-col bg-gray-50 text-gray-800">
    <header class="bg-wink-yellow/80 backdrop-blur-md shadow-md sticky top-0 z-50">
      <div class="container mx-auto px-4 py-4 flex justify-between items-center">
        <div class="flex items-center space-x-2">
          <img src="/wink_no-1.png" alt="Wink Logo" class="w-10 h-10" />
          <span class="text-2xl font-bold text-gray-800">Wink</span>
        </div>
        <nav class="space-x-6 flex items-center">
          <a v-for="item in navItems" :key="item.href" :href="item.href" class="text-gray-800 hover:text-gray-600 transition-colors">
            {{ item[currentLanguage] }}
          </a>
          <button @click="toggleLanguage" class="bg-yellow-400/80 backdrop-blur-sm bg-gray-800 px-3 py-1 rounded transition-colors">
            {{ currentLanguage === 'zh' ? 'EN' : '中文' }}
          </button>
          <SignedOut>
            <SignInButton mode="modal">
              <button class="text-gray-800 hover:text-gray-600 transition-colors">
                {{ content.signIn[currentLanguage] }}
              </button>
            </SignInButton>
          </SignedOut>
          <SignedIn>
            <UserButton 
              :appearance="{
                elements: {
                  avatarBox: 'w-8 h-8',
                  userPreviewMainIdentifier: 'text-sm font-medium text-gray-800',
                  userPreviewSecondaryIdentifier: 'text-xs text-gray-500'
                }
              }"
            />
          </SignedIn>
        </nav>
      </div>
    </header>

    <main class="flex-grow">
      <section class="container mx-auto px-4 py-20 text-center">
        <img src="/wink_no-1.png" alt="Wink Logo" class="mx-auto w-32 h-32 mb-6" />
        <h1 class="text-4xl md:text-6xl font-bold mb-6 text-wink-yellow">
          Wink
        </h1>
        <p class="text-xl md:text-2xl mb-8 max-w-3xl mx-auto text-gray-600" v-html="content.slogan[currentLanguage]"></p>
        <a href="#products" class="bg-wink-yellow hover:bg-yellow-400 text-gray-800 px-8 py-3 rounded-full text-lg transition-colors shadow-lg hover:shadow-xl inline-block">
          {{ content.learnMore[currentLanguage] }} <span class="ml-2">→</span>
        </a>
      </section>

      <section id="products" class="container mx-auto px-4 py-20">
        <h2 class="text-3xl md:text-4xl font-bold mb-12 text-center text-wink-yellow">{{ content.ourProducts[currentLanguage] }}</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div v-for="product in products" :key="product.name" 
               class="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-1 border-b-4 border-wink-yellow">
            <component :is="product.icon" class="w-12 h-12 mb-4 text-wink-yellow" />
            <h3 class="text-xl font-semibold mb-2 text-wink-yellow">{{ product.name }}</h3>
            <p class="text-sm font-medium mb-2 text-gray-600">{{ product.subtitle[currentLanguage] }}</p>
            <p class="mb-4 text-gray-600">{{ product.description[currentLanguage] }}</p>
            <a :href="product.link" target="_blank" class="text-wink-yellow hover:text-yellow-400 hover:underline">
              {{ product.linkText[currentLanguage] }} →
            </a>
          </div>
        </div>
      </section>

      <section class="w-full py-12 md:py-24 lg:py-32 bg-gray-100 overflow-hidden">
        <div class="container mx-auto px-4 md:px-6">
          <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12 text-wink-yellow">
            {{ content.userRecommendations[currentLanguage] }}
          </h2>
          <div class="relative">
            <div class="flex flex-wrap justify-center gap-8">
              <div v-for="(testimonial, index) in testimonials" :key="index" 
                   :class="`bg-white p-6 rounded-lg shadow-lg w-full sm:w-[calc(50%-1rem)] md:w-[calc(33.333%-1.5rem)] lg:w-[calc(25%-1.5rem)] xl:w-[calc(20%-1.6rem)] testimonial-card testimonial-card-${index + 1} flex flex-col`">
                <p class="text-gray-600 mb-4 flex-grow">{{ testimonial.content[currentLanguage] }}</p>
                <div class="flex items-center mt-auto">
                  <img :src="testimonial.avatar" alt="User avatar" class="w-12 h-12 rounded-full mr-4" />
                  <div>
                    <h4 class="font-bold text-wink-yellow">{{ testimonial.name }}</h4>
                    <p class="text-sm text-gray-500">{{ testimonial.position[currentLanguage] }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" class="bg-gray-100 py-20">
        <div class="container mx-auto px-4">
          <h2 class="text-3xl md:text-4xl font-bold mb-8 text-center text-wink-yellow">{{ content.aboutUs[currentLanguage] }}</h2>
          <div class="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
            <p class="text-lg text-center text-gray-600" v-html="content.aboutText[currentLanguage]"></p>
            <div class="mt-8 flex flex-wrap justify-center gap-8">
              <div v-for="value in companyValues" :key="value.title" class="text-center w-full sm:w-auto">
                <div class="w-16 h-16 mx-auto bg-wink-yellow rounded-full flex items-center justify-center mb-2">
                  <component :is="value.icon" class="w-12 h-12 text-gray-800" />
                </div>
                <h3 class="font-semibold text-wink-yellow">{{ value.title[currentLanguage] }}</h3>
                <p class="text-sm text-gray-600 max-w-[200px] mx-auto">{{ value.description[currentLanguage] }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <footer class="bg-gray-800 py-8 text-white">
      <div class="container mx-auto px-4 text-center">
        <p>&copy; 2025 Wink. {{ content.allRightsReserved[currentLanguage] }}. <a href="https://wink.run/policy.html" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">{{ content.privacyPolicy[currentLanguage] }}</a>.</p>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { inject, computed, onMounted, unref } from 'vue'
import { useHead } from '@vueuse/head'
import { FileText, Bookmark, Rss, Zap, Users, Shield } from 'lucide-vue-next'
import { SignInButton, SignedIn, SignedOut, UserButton } from 'vue-clerk'

const currentLanguage = inject('currentLanguage')
const toggleLanguage = inject('toggleLanguage')

const content = computed(() => ({
  slogan: {
    zh: '一眼倾心，为您而来。<br>以用户为先的AI，告别平台偏见，<br>让技术与您心意相通。',
    en: 'A wink, a spark, a perfect match.<br>User-first AI, unbiased, true to you,<br>Where technology winks in harmony with you.'
  },
  learnMore: { zh: '了解更多', en: 'Learn More' },
  ourProducts: { zh: '我们的产品', en: 'Our Products' },
  aboutUs: { zh: '关于我们', en: 'About Us' },
  aboutText: {
    zh: 'Wink致力于打造AI原生的创新产品，告别平台偏见，忠于用户初心，提供专属于用户的个性化智能体验。我们的使命是将尖端人工智能与用户需求完美融合，缔造真正富有价值的产品之旅。',
    en: 'Wink is dedicated to crafting AI-driven innovative products, bidding farewell to platform biases and staying true to our users\' original aspirations. We offer personalized intelligent experiences tailored exclusively to each user. Our mission is to seamlessly blend cutting-edge artificial intelligence with user needs, creating a truly valuable product journey.'
  },
  allRightsReserved: { zh: '版权所有', en: 'All rights reserved' },
  privacyPolicy: { zh: '隐私权政策', en: 'Privacy Policy' },
  userRecommendations: { zh: '用户推荐', en: 'User Recommendations' },
  signIn: {
    zh: '登录',
    en: 'Sign In'
  }
}))

const navItems = [
  { href: '#products', zh: '产品', en: 'Products' },
  { href: '#about', zh: '关于', en: 'About' },
]

const products = [
  {
    name: 'OfferWink',
    icon: FileText,
    subtitle: {
      zh: '您的简历优化专家',
      en: 'Your resume optimization expert!'
    },
    description: {
      zh: 'AI原生的简历优化工具，智能分析您的简历，结合岗位目标，提供专业优化建议，生成个性化简历和面试题答案，助您脱颖而出。',
      en: 'AI-powered resume optimization tool that intelligently analyzes your resume, combines it with job requirements, provides professional optimization suggestions, generates personalized resumes and interview question answers, helping you stand out.'
    },
    link: '/offerwink',
    linkText: { zh: '了解更多', en: 'Learn more' }
  },
  {
    name: 'MemWink',
    icon: Bookmark,
    subtitle: {
      zh: '让您的记忆熠熠生辉！',
      en: 'Let your memory wink！'
    },
    description: {
      zh: 'AI原生的Chrome扩展，可以替代原生书签和阅读列表，支持主题分类和内容总结。',
      en: 'An AI-driven Chrome Extension that can replace native bookmarks and reading list, and it also supports topic classification and content summarization.'
    },
    link: '/memwink',
    linkText: { zh: '立即体验', en: 'Try it now' }
  },
  {
    name: 'AI News & Insights Newsletter',
    icon: Rss,
    subtitle: {
      zh: 'AI前沿资讯及技术洞察速递',
      en: 'Cutting-edge AI News & Tech Insights Delivery'
    },
    description: {
      zh: '利用AI Agent技术搜罗全网最新资讯以及深度独家洞察，让您从广度和深度了解前沿AI产品技术信息，助您洞悉行业趋势',
      en: 'Utilize AI Agent technology to scour the entire internet for the latest news and in-depth exclusive insights, allowing you to understand cutting-edge AI product & tech information from a breadth and depth perspective, helping you stay ahead of industry trends'
    },
    link: '#',
    linkText: { zh: '敬请期待', en: 'Coming soon' }
  }
]

const testimonials = [
  {
    content: {
      zh: "简历优化专家帮我重新梳理了职业经历，突出了我的核心优势。面试邀请明显增多了！",
      en: "The resume optimization expert helped me reorganize my career experience, highlighting my core strengths. Interview invitations have significantly increased!"
    },
    name: "张明",
    position: { zh: "软件工程师", en: "Software Engineer" },
    avatar: "https://i.pravatar.cc/150?img=3"
  },
  {
    content: {
      zh: "MemWink彻底改变了我管理在线信息的方式。它不仅仅是一个书签工具，而是我的个人知识管理助手。主题分类和内容总结功能让我能够轻松找到并利用我收集的信息。",
      en: "MemWink has completely transformed how I manage online information. It's not just a bookmark tool, but my personal knowledge management assistant. The topic classification and content summarization features allow me to easily find and utilize the information I've collected."
    },
    name: "Sarah Johnson",
    position: { zh: "研究员", en: "Researcher" },
    avatar: "https://i.pravatar.cc/150?img=6"
  },
  {
    content: {
      zh: "AI生成的面试问题和答案给了我很大帮助，让我在面试中更加自信。强烈推荐！",
      en: "The AI-generated interview questions and answers were a great help, making me more confident in interviews. Highly recommended!"
    },
    name: "李华",
    position: { zh: "市场经理", en: "Marketing Manager" },
    avatar: "https://i.pravatar.cc/150?img=2"
  },
  {
    content: {
      zh: "使用MemWink后，我再也不用担心打开无数标签页或丢失重要信息了。它就像一个智能助手，总是在我需要的时候提供正确的信息。太棒了！",
      en: "After using MemWink, I no longer worry about opening countless tabs or losing important information. It's like a smart assistant, always providing the right information when I need it. Amazing!"
    },
    name: "David Chen",
    position: { zh: "内容创作者", en: "Content Creator" },
    avatar: "https://i.pravatar.cc/150?img=7"
  },
  {
    content: {
      zh: "简历优化专家让我的求职过程变得轻松多了。强烈推荐给所有求职者！",
      en: "The resume optimization expert made my job search process much easier. Strongly recommended for all job seekers!"
    },
    name: "陈静",
    position: { zh: "产品经理", en: "Product Manager" },
    avatar: "https://i.pravatar.cc/150?img=5"
  }
]

const companyValues = [
  {
    icon: Zap,
    title: { zh: 'AI原生', en: 'AI Native' },
    description: { zh: '前沿AI技术与新一代用户体验', en: 'Advanced AI Technology & Next-Generation User Experience' }
  },
  {
    icon: Users,
    title: { zh: '用户为先', en: 'User-First' },
    description: { zh: '告别平台偏见，忠于用户初心', en: 'Unbiased & true to users' }
  },
  {
    icon: Shield,
    title: { zh: '可信可靠', en: 'Trustworthy' },
    description: { zh: '隐私安全优先、用户可控', en: 'Privacy Security First & User-Controlled' }
  }
]

// SEO-related metadata
useHead({
  title: computed(() => currentLanguage.value === 'zh' ? 'Wink - 技术与您心意相通' : 'Wink - Where technology winks in harmony with you'),
  meta: [
    {
      name: 'description',
      content: computed(() => currentLanguage.value === 'zh' 
        ? 'Wink提供AI原生的无平台偏见的创新产品。利用先进的人工智能技术，帮助您打造完美简历，提升求职竞争力。'
        : 'Wink offers AI-driven innovative products without platform bias. Using advanced AI technology to help you create the perfect resume and enhance your job competitiveness.')
    },
    { name: 'keywords', content: 'AI, 人工智能, 用户体验, 创新产品, OfferWink, MemWink' },
    { property: 'og:title', content: computed(() => currentLanguage.value === 'zh' ? 'Wink - 技术与您和谐共鸣' : 'Wink - Where technology winks in harmony with you') },
    { property: 'og:description', content: computed(() => currentLanguage.value === 'zh' 
        ? 'Wink提供AI原生的无平台偏见的创新产品。利用先进的人工智能技术，帮助您打造完美简历，提升求职竞争力。'
        : 'Wink offers AI-driven innovative products without platform bias. Using advanced AI technology to help you create the perfect resume and enhance your job competitiveness.') },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.wink.run' }, 
    { property: 'og:image', content: 'https://www.wink.run/wink_no-1.png' }, 
  ],
  link: [
    { rel: 'canonical', href: 'https://www.wink.run' },
  ],
})

// Structured data for rich snippets
onMounted(() => {
  const script = document.createElement('script')
  script.setAttribute('type', 'application/ld+json')
  script.textContent = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Wink",
    "description": unref(currentLanguage) === 'zh' 
        ? 'Wink提供AI原生的无平台偏见的创新产品。利用先进的人工智能技术，帮助您打造完美简历，提升求职竞争力。'
        : 'Wink offers AI-driven innovative products without platform bias. Using advanced AI technology to help you create the perfect resume and enhance your job competitiveness.',
    "url": "https://www.wink.run",
    "logo": "https://www.wink.run/wink_no-1.png"
  })
  document.head.appendChild(script)
})
</script>

<style scoped>
:root {
  --wink-yellow: #ffc72c;
}

.bg-wink-yellow {
  background-color: var(--wink-yellow);
}

.text-wink-yellow {
  color: var(--wink-yellow);
}

.border-wink-yellow {
  border-color: var(--wink-yellow);
}

.hover\:bg-wink-yellow:hover {
  background-color: var(--wink-yellow);
}

.hover\:text-wink-yellow:hover {
  color: var(--wink-yellow);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-20px) rotate(-2deg);
  }
  75% {
    transform: translateY(10px) rotate(2deg);
  }
}

.testimonial-card {
  animation: float 10s ease-in-out infinite;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.testimonial-card-1 { animation-delay: 0s; }
.testimonial-card-2 { animation-delay: -2s; }
.testimonial-card-3 { animation-delay: -4s; }
.testimonial-card-4 { animation-delay: -6s; }
.testimonial-card-5 { animation-delay: -8s; }

@media (min-width: 768px) {
  .testimonial-card-1 { margin-top: 40px; }
  .testimonial-card-2 { margin-top: -20px; }
  .testimonial-card-3 { margin-top: 60px; }
  .testimonial-card-4 { margin-top: 10px; }
  .testimonial-card-5 { margin-top: -40px; }
}

@media (min-width: 640px) {
  #about .container {
    max-width: 90%;
  }
}

@media (min-width: 1024px) {
  #about .container {
    max-width: 80%;
  }
}

.nav-link {
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.nav-link:hover {
  background-color: #f0f0f0;
}

.backdrop-blur-md {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.backdrop-blur-sm {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

/* 确保毛玻璃效果在Safari上也能正常工作 */
@supports not (backdrop-filter: blur(12px)) {
  .backdrop-blur-md {
    background-color: rgba(255, 199, 44, 0.95);
  }
  
  .backdrop-blur-sm {
    background-color: rgba(255, 199, 44, 0.9);
  }
}
</style>