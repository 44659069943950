<template>
  <div class="min-h-screen bg-gray-50 flex flex-col">
    <!-- Header -->
    <header class="bg-wink-yellow shadow-md">
      <div class="container mx-auto px-4 py-4 flex justify-between items-center">
        <div class="flex items-center space-x-2">
          <router-link to="/" class="flex items-center">
            <img src="/wink_no-1.png" alt="Wink Logo" class="w-10 h-10" />
            <span class="text-2xl font-bold text-gray-800">Wink</span>
          </router-link>
        </div>
        <nav class="space-x-6">
          <router-link 
            v-for="item in navItems" 
            :key="item.path" 
            :to="item.path"
            class="text-gray-800 hover:text-gray-600 transition-colors"
          >
            {{ item[currentLanguage] }}
          </router-link>
          <button @click="toggleLanguage" 
                  class="bg-yellow-400 bg-gray-800 px-3 py-1 rounded transition-colors">
            {{ currentLanguage === 'zh' ? 'EN' : '中文' }}
          </button>
        </nav>
      </div>
    </header>

    <!-- Main Content -->
    <div class="flex-grow">
      <div class="container mx-auto px-4 py-8">
        <SignedIn>
          <div class="max-w-3xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
            <div class="bg-wink-yellow p-8 text-center">
              <div class="relative inline-block">
                <img 
                  :src="user?.imageUrl || '/wink_no-1.png'" 
                  alt="Profile" 
                  class="w-32 h-32 rounded-full border-4 border-white shadow-md mx-auto"
                >
              </div>
              <h2 class="text-3xl font-bold text-gray-800 mt-4">{{ t.profileTitle }}</h2>
            </div>
            
            <div class="p-8">
              <div class="space-y-6">
                <div class="info-item">
                  <label class="text-gray-600">{{ t.username }}</label>
                  <p class="text-lg font-medium text-gray-800">
                    {{ formatUsername(user) }}
                  </p>
                </div>
                <div class="info-item">
                  <label class="text-gray-600">{{ t.email }}</label>
                  <p class="text-lg font-medium text-gray-800">
                    {{ user?.emailAddresses?.[0]?.emailAddress }}
                  </p>
                </div>
                <div class="info-item">
                  <label class="text-gray-600">{{ t.registrationDate }}</label>
                  <p class="text-lg font-medium text-gray-800">
                    {{ formatDate(user?.createdAt) }}
                  </p>
                </div>
              </div>

              <div class="mt-8 flex justify-center">
                <button 
                  @click="signOut" 
                  class="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-8 rounded-full transition-colors duration-300 flex items-center gap-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3 3a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3zm11 4.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L11.586 7H6a1 1 0 1 1 0-2h5.586L8.293 1.707a1 1 0 0 1 1.414-1.414L14 4.586v2.828z" clip-rule="evenodd" />
                  </svg>
                  {{ t.signOut }}
                </button>
              </div>
            </div>
          </div>
        </SignedIn>

        <SignedOut>
          <div class="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8 text-center">
            <img src="/wink_no-1.png" alt="Wink Logo" class="w-24 h-24 mx-auto mb-6" />
            <h2 class="text-3xl font-bold text-gray-800 mb-6">{{ t.pleaseSignIn }}</h2>
            <SignInButton mode="modal">
              <button class="bg-wink-yellow hover:bg-yellow-400 text-gray-800 font-bold py-3 px-8 rounded-full transition-colors duration-300 shadow-lg hover:shadow-xl">
                {{ t.signIn }}
              </button>
            </SignInButton>
          </div>
        </SignedOut>
      </div>
    </div>

    <!-- Footer -->
    <footer class="bg-gray-800 py-8 text-white">
      <div class="container mx-auto px-4 text-center">
        <p>&copy; 2025 Wink. {{ content.allRightsReserved[currentLanguage] }}. 
          <a href="https://wink.run/policy.html" target="_blank" rel="noopener noreferrer" 
             class="text-blue-600 hover:underline">
            {{ content.privacyPolicy[currentLanguage] }}
          </a>.
        </p>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { SignedIn, SignedOut, SignInButton, useUser, useClerk } from 'vue-clerk'
import { inject, computed } from 'vue'

const currentLanguage = inject('currentLanguage')
const toggleLanguage = inject('toggleLanguage')

const { user } = useUser()
const clerk = useClerk()

const formatDate = (dateString) => {
  if (!dateString) return ''
  const date = new Date(dateString)
  const options = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }
  return date.toLocaleDateString(currentLanguage.value === 'zh' ? 'zh-CN' : 'en-US', options)
}

const signOut = async () => {
  await clerk.signOut()
}

const navItems = [
{
    path: '/',
    zh: '首页',
    en: 'Home'
  },
  { 
    path: 'https://chromewebstore.google.com/detail/memwink-ai-driven-bookmar/mpklgnebhehdpdpddabokaocemmalijn',
    zh: 'MemWink',
    en: 'MemWink'
  },
  { 
    path: '/offerwink', 
    zh: 'OfferWink', 
    en: 'OfferWink'
  },
  
]

const t = computed(() => ({
  profileTitle: currentLanguage.value === 'zh' ? '个人信息' : 'Profile',
  username: currentLanguage.value === 'zh' ? '用户名' : 'Username',
  email: currentLanguage.value === 'zh' ? '邮箱' : 'Email',
  registrationDate: currentLanguage.value === 'zh' ? '注册时间' : 'Registration Date',
  signOut: currentLanguage.value === 'zh' ? '退出登录' : 'Sign Out',
  pleaseSignIn: currentLanguage.value === 'zh' ? '请先登录' : 'Please Sign In',
  signIn: currentLanguage.value === 'zh' ? '登录' : 'Sign In',
}))

const content = computed(() => ({
  allRightsReserved: { 
    zh: '版权所有', 
    en: 'All rights reserved' 
  },
  privacyPolicy: { 
    zh: '隐私权政策', 
    en: 'Privacy Policy' 
  }
}))

const formatUsername = (user) => {
  if (!user) return '-'
  
  if (user.username) {
    return user.username
  }
  
  const firstName = user.firstName || ''
  const lastName = user.lastName || ''
  
  if (firstName || lastName) {
    return `${firstName} ${lastName}`.trim()
  }
  
  return user.emailAddresses?.[0]?.emailAddress?.split('@')[0] || '-'
}
</script>

<style scoped>
:root {
  --wink-yellow: #ffc72c;
}

.bg-wink-yellow {
  background-color: var(--wink-yellow);
}

.info-item {
  @apply border-b border-gray-100 pb-4;
}

.info-item label {
  @apply text-sm uppercase tracking-wider mb-1 block;
}

/* 添加一些动画效果 */
.info-item {
  transition: all 0.3s ease;
}

.info-item:hover {
  transform: translateX(8px);
}

button {
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-2px);
}

/* 响应式调整 */
@media (max-width: 640px) {
  .container {
    padding: 1rem;
  }
  
  .info-item label {
    font-size: 0.875rem;
  }
  
  .info-item p {
    font-size: 1rem;
  }
}

/* Additional header styles */
header {
  position: sticky;
  top: 0;
  z-index: 50;
}

.nav-link {
  @apply text-gray-800 hover:text-gray-600 transition-colors;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  nav {
    @apply hidden;
  }
  
  .container {
    @apply px-4;
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  nav {
    @apply space-x-4;
  }
  
  nav a {
    @apply text-sm;
  }
}
</style> 