import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '@/views/IndexView.vue'
import IntroView from '@/views/IntroView.vue'
import ResumeUploader from '@/components/ResumeUploader.vue'
import ResumeResults from '@/components/ResumeResults.vue'
import SignIn from '@/components/SignIn.vue'
import ProfileView from '../views/ProfileView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: IndexView
  },
  {
    path: '/offerwink',
    name: 'OfferWink',
    component: IntroView
  },
  {
    path: '/upload',
    name: 'ResumeUploader',
    component: ResumeUploader
  },
  {
    path: '/results',
    name: 'ResumeResults',
    component: ResumeResults
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/media-tools',
    name: 'media-tools',
    component: () => import('../views/MediaToolsView.vue')
  },
  {
    path: '/markdown-editor',
    name: 'markdown-editor',
    component: () => import('../views/MarkdownEditorView.vue')
  },
  {
    path: '/material-library',
    name: 'MaterialLibrary',
    component: () => import('../views/MaterialLibraryView.vue')
  },
  {
    path: '/subtitle-converter',
    name: 'SubtitleConverter',
    component: () => import('../views/SubtitleConverterView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/share-card',
    name: 'share-card',
    component: () => import('../views/ShareCardView.vue')
  },
  {
    path: '/memwink_settings',
    name: 'memwink_settings',
    component: () => import('../views/SettingsView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/memwink',
    name: 'memwink',
    component: () => import('../views/MemWinkView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router