<template>
  <div class="container mx-auto p-4 flex flex-col min-h-screen">
    <div class="flex-grow">
    

      <div class="bg-white rounded-lg shadow-md p-6 relative">
        <div class="flex justify-between items-center mb-6">
          <h1 class="text-3xl font-bold text-center text-blue-800">{{ t.title }}</h1>
          <div>
            <button @click="toggleLanguage" class="text-sm font-medium hover:underline underline-offset-4 mr-4">
              {{ currentLanguage === 'en' ? '中文' : 'English' }}
            </button>
            <SignedOut>
              <SignInButton mode="modal" forceRedirectUrl="/upload">
                <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300">
                  {{ t.signIn }}
                </button>
              </SignInButton>
              
            </SignedOut>
            <SignedIn forceRedirectUrl="/upload">
              <UserButton showName="true"/>
            </SignedIn>
          
          </div>
        </div>
        <div class="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-6 rounded-r-lg shadow-md">
          <p class="text-sm">
            {{ t.introduction }}
            <br><br>
            <b>{{ t.privacyNote }}</b>
            <br><br>
            {{ t.callToAction }}
          </p>
        </div>
        <h2 class="text-2xl font-semibold text-blue-600 mb-4">{{ t.uploadTitle }}</h2>
        <SignedIn>
          <div 
            class="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:border-blue-500 transition-colors duration-300"
            @drop.prevent="handleDrop"
            @dragover.prevent
            @click="$refs.fileInput.click()"
          >
            <input type="file" @change="handleFileUpload" ref="fileInput" class="hidden" accept=".pdf,.doc,.docx">
            <p class="text-gray-600 mb-2">{{ t.dragDropText }}</p>
            <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300">
              {{ t.selectFileButton }}
            </button>
          </div>
          <div v-if="file" class="mt-4">
            <p class="text-gray-700">{{ t.selectedFile }} {{ file.name }}</p>
            <button 
              @click="uploadFile" 
              class="mt-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
              :disabled="isUploading"
            >
              <span v-if="!isUploading">{{ t.uploadButton }}</span>
              <span v-else>{{ t.processingText }}</span>
            </button>
          </div>
          <!-- 浮动广告遮罩 -->
          <div v-if="showAd" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div class="bg-white p-6 rounded-lg shadow-xl text-center">
              <amp-ad width="100vw" height="320"
       type="adsense"
       data-ad-client="ca-pub-9160155823114008"
       data-ad-slot="2675825954"
       data-auto-format="rspv"
       data-full-width="">
    <div overflow=""></div>
          </amp-ad>
              <p class="text-gray-600 mb-2">{{ t.adText }}</p>
              <p class="text-xl font-bold">{{ elapsedTime }} {{ t.timeElapsed }}</p>
              <p class="text-sm text-gray-500 mt-2">{{ t.adCloseText }}</p>
            </div>
          </div>
          <!-- 结果区域 -->
          <div v-if="showResults" class="mt-6">
            <div v-if="optimizedContent" class="bg-gray-100 p-4 rounded-lg">
              <h3 class="text-xl font-semibold text-blue-600 mb-2">{{ t.optimizedResumeTitle }}</h3>
              <pre class="whitespace-pre-wrap break-words text-sm">{{ optimizedContent }}</pre>
            </div>
            <div v-if="errorMessage" class="mt-4 text-red-500">
              {{ errorMessage }}
            </div>
          </div>
        </SignedIn>

        <SignedOut>
          <div class="mt-6 text-center">
            <p>{{ t.loginPrompt }}</p>
            <SignInButton mode="modal" forceRedirectUrl="/upload">
              <button class="mt-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300">
                {{ t.signIn }}
              </button>
            </SignInButton>
          </div>
        </SignedOut>

      </div>

 

      <!-- 新增页脚部分 -->
      <footer class="mt-8 py-4 border-t border-gray-200">
        <div class="text-center text-sm text-gray-600" v-html="t.footer"></div>
      </footer>
    </div>
  </div>
</template>

<script>
import { SignedIn, SignedOut, SignInButton,UserButton, useUser } from 'vue-clerk'
// 删除 ref 的导入，如果你不需要使用它
import axios from 'axios'
import { useRouter } from 'vue-router'

export default {
  name: 'ResumeUploader',
  components: {
    SignedIn,
    SignedOut,
    SignInButton,
    UserButton,
  },
  inject: ['currentLanguage', 'toggleLanguage'],
  setup() {
    const router = useRouter();
    const { isSignedIn, user } = useUser();
    return { router, isSignedIn, user };
  },
  data() {
    return {
      file: null,
      optimizationLevel: 'basic',
      optimizedContent: null,
      isUploading: false,
      errorMessage: null,
      adImageUrl: 'wink_no-1.png',
      showResults: false,
      showAd: false,
      processingComplete: false,
      elapsedTime: 0,
      countupInterval: null,
      resumeData: null,
      translations: {
        zh: {
          title: 'OfferWink-您的简历优化专家',
          introduction: '我们的AI原生工具能智能分析您的简历，结合岗位目标，为您提供专业的优化建议，生成专属于您的个性化简历和配套面试题及答案，帮助您脱颖而出。',
          privacyNote: '本工具不保存上传的个人信息，确保您的隐私安全。',
          callToAction: '让我们一起打造一份出色的简历吧！',
          uploadTitle: '上传您的简历',
          dragDropText: '拖拽文件到这里或',
          selectFileButton: '选择文件',
          selectedFile: '已选择文件:',
          uploadButton: '上传并优化',
          processingText: '处理中...',
          adText: '请稍候，我们正在优化您的简历...',
          timeElapsed: '秒',
          adCloseText: '处理完成后将自动关闭（约80秒）',
          optimizedResumeTitle: '优化后的简历',
          footer: '© 2025 <a href="https://wink.run" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">wink.run</a> 出品。保留所有权利。了解我们的<a href="https://wink.run/policy.html" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">隐私权政策</a>.',
          fileRequiredError: '请先选择一个文件。',
          uploadError: '上传文件时发生错误。请重试。',
          signIn: '登录',
          signUp: '注册',
          loginPrompt: '请登录或注册再继续...',
        },
        en: {
          title: 'OfferWink - Your Resume Optimization Expert',
          introduction: 'Our AI-driven tool intelligently analyzes your resume, aligns it with job targets, provides professional optimization suggestions, and generates personalized resumes and interview Q&As to help you stand out.',
          privacyNote: 'This tool does not save uploaded personal information, ensuring your privacy.',
          callToAction: 'Let\'s create an outstanding resume together!',
          uploadTitle: 'Upload Your Resume',
          dragDropText: 'Drag and drop file here or',
          selectFileButton: 'Select File',
          selectedFile: 'Selected file:',
          uploadButton: 'Upload and Optimize',
          processingText: 'Processing...',
          adText: 'Please wait, we are optimizing your resume...',
          timeElapsed: 'seconds',
          adCloseText: 'Will close automatically after processing (about 80 seconds)',
          optimizedResumeTitle: 'Optimized Resume',
          footer: '© 2025 by <a href="https://wink.run" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">wink.run</a>. All rights reserved. Learn more about our <a href="https://wink.run/policy.html" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">Privacy Policy</a>.',
          fileRequiredError: 'Please select a file first.',
          uploadError: 'An error occurred while uploading the file. Please try again.',
          signIn: 'Sign In',
          signUp: 'Sign Up',
          loginPrompt: 'Please sign in or sign up to use',
        }
      }
    }
  },
  computed: {
    t() {
      const lang = this.currentLanguage || 'en'
      return this.translations[lang] || this.translations['en']
    }
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0]
    },
    handleDrop(event) {
      this.file = event.dataTransfer.files[0]
    },
    async uploadFile() {
      if (!this.isSignedIn) {
        this.errorMessage = this.t.loginPrompt
        return
      }

      if (!this.file) {
        this.errorMessage = this.t.fileRequiredError
        return
      }

      this.isUploading = true
      this.errorMessage = null
      this.optimizedContent = null
      this.showResults = false
      this.showAd = true
      this.processingComplete = false
      this.startCountup()

      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('optimization_level', this.optimizationLevel)

      try {
        const response = await axios.post('/api/upload_resume', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response.data && response.data.resumeData) {
          this.resumeData = response.data.resumeData
          this.processingComplete = true
          sessionStorage.setItem('resumeData', this.resumeData)
          sessionStorage.setItem('originalFileName', this.file.name)
          this.navigateToResults()
        } else {
          throw new Error('Invalid response from server')
        }
      } catch (error) {
        console.error('上传文件时出错:', error)
        this.errorMessage = this.t.uploadError
      } finally {
        this.isUploading = false
        this.stopCountup()
        this.showAd = false
      }
    },
    startCountup() {
      this.elapsedTime = 0
      this.countupInterval = setInterval(() => {
        this.elapsedTime++
      }, 1000)
    },
    stopCountup() {
      clearInterval(this.countupInterval)
      this.countupInterval = null
    },
    navigateToResults() {
      //console.log("正在导航到结果页面，内容:", this.resumeData)
      this.router.push({ name: 'ResumeResults' })
    }
  },
  beforeUnmount() {
    this.stopCountup()
  }
}
</script>

<style scoped>
footer {
  margin-top: auto;
}
</style>